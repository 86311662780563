import type { ITemplateActions } from '../interfaces/templates'
import type { IContainers, UnresolvedRecord } from '../interfaces'
import { RootState } from '../redux/store'
import { processActions } from '../utils/processActions'
import processData from '../utils/processData'

const getCinnamonProps = (
  props: UnresolvedRecord,
  state: RootState,
  actions: ITemplateActions,
) => {
  const cinnamonProps = processData(props, { state })
  return processActions(cinnamonProps, actions)
}

const processDataValidated = (
  data: UnresolvedRecord | undefined = {},
  state: RootState,
) => processData(data, { state })

export const getCinnamonPropsContainer = (
  container: IContainers,
  state: RootState,
  actions: ITemplateActions,
) => {
  return {
    ...container,
    header: {
      props: processDataValidated(container.header?.props, state),
      components: container.header?.components.map(
        ({ props = {}, ...rest }) => ({
          ...rest,
          props: getCinnamonProps(props, state, actions),
        }),
      ),
    },
    body: {
      props: processDataValidated(container.body?.props, state),
      components: container.body?.components.map(({ props = {}, ...rest }) => ({
        ...rest,
        props: getCinnamonProps(props, state, actions),
      })),
    },
    footer: {
      props: processDataValidated(container.footer?.props, state),
      components: container.footer?.components.map(
        ({ props = {}, ...rest }) => ({
          ...rest,
          props: getCinnamonProps(props, state, actions),
        }),
      ),
    },
  }
}

export default getCinnamonProps
