export * from './useAppDispatch'
export * from './useAppSelector'
export * from './useBack'
export * from './useBackInStock'
export * from './useBannersClick'
export * from './useCinnamonProps'
export * from './useCreateOrder'
export * from './useLoadDialogValidations'
export * from './useModal'
export * from './usePlaceOrder'
export * from './useProductDetail'
export * from './useDialog'
export * from './usePushView'
export * from './useSearch'

export * from './cart'
export * from './catalog'
export * from './categories'
export * from './categoriesnav'
export * from './filters'
export * from './linkedProducts'
export * from './prefillCart'
export * from './suggestedItems'
export * from './summary'
export * from './templates'

export * from './useCinnamonActions'

export const useLogAction = () => (text: string) =>
  // eslint-disable-next-line
  console.log('action executed', text)
