import { AppContainer, ModalBox } from '@engyalo/delivery-ui-components'
import ReactGA from 'react-ga4'
import { Suspense, useEffect } from 'react'
import { useLoadViews } from '../hooks/templates/useLoadViews'
import useTemplateComponentsState from '../hooks/templates/useTemplateComponentsState'
import { IModal, IModals } from '../interfaces'
import { IComponentPromise, ITemplateActions } from '../interfaces/templates'
import { RootState } from '../redux/store'
import { useAppSelector, useModal } from '../hooks'

interface IModalContainer {
  name: string
  modal: IModal
  actions: ITemplateActions
  state: RootState
  modalStatus: IModals
}

const ModalContainer = ({
  name = '',
  modal,
  actions,
  state,
  modalStatus,
}: IModalContainer) => {
  const { closeModal, performModalActions } = useModal()
  const modals = useAppSelector((state) => state.defaultSlice.modals)
  const {
    uid: modalUid,
    header = { components: [] },
    body = { components: [] },
    footer = { components: [] },
    props = {},
  } = modal
  const { show } = modalStatus[name]
  const { props: headerProps, components: headerComponents } = header
  const { props: bodyProps, components: bodyComponents } = body
  const { props: footerProps, components: footerComponents } = footer
  useEffect(() => {
    if (show) {
      ReactGA.send({ hitType: 'pageview', page: `/${name}` })
      window.fbq('track', 'PageView', { page: `/${name}` })
    }
  }, [name, show])

  const {
    headerComponentsList,
    setHeaderComponentsList,
    bodyComponentsList,
    setBodyComponentsList,
    footerComponentsList,
    setFooterComponentsList,
  } = useTemplateComponentsState()

  useLoadViews({
    headerComponents,
    setHeaderComponentsList,
    bodyComponents,
    setBodyComponentsList,
    footerComponents,
    setFooterComponentsList,
    containerUid: modalUid,
  })

  const renderComponentWrapper = ({
    wrapper: Wrapper,
    uid,
  }: IComponentPromise) => (
    <Wrapper state={state} actions={actions} parentUid={modalUid} key={uid} />
  )

  const closeActions = () => {
    closeModal({ container: name, state })
    performModalActions({ container: name, modalStatus: !!modals?.[name].show })
  }

  return (
    <Suspense fallback={null}>
      <ModalBox
        id={modalUid}
        open={show}
        closeActions={closeActions}
        {...props}>
        <AppContainer
          header={{
            ...headerProps,
            id: `${modalUid}-header`,
            components: headerComponentsList.map(renderComponentWrapper),
          }}
          body={{
            ...bodyProps,
            id: `${modalUid}-body`,
            components: bodyComponentsList.map(renderComponentWrapper),
          }}
          footer={{
            ...footerProps,
            id: `${modalUid}-footer`,
            components: footerComponentsList.map(renderComponentWrapper),
          }}
        />
      </ModalBox>
    </Suspense>
  )
}

export default ModalContainer
